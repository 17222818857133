<script lang="ts">
  import CareersSection from '@components/Careers/CareersSection/CareersSection.svelte';

  import RadarCircles from './components/RadarCircles/RadarCircles.svelte';
  import RadarLegend from './components/RadarLegend.svelte';
</script>

<CareersSection
  sectionCss="bg-black-carbon"
  floatingLabel="OUR STACK"
  floatingLabelCss="text-white"
>
  <h2 class="section-title col-span-11 leading-none">
    <span class="text-white">
      <span class="text-careers-basic-secondary"> Our</span><br />
      Technology <br />
      Stack
    </span>
  </h2>

  <RadarCircles />
  <RadarLegend />
</CareersSection>
