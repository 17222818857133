<script lang="ts">
  import BetweenCurlyBraces from '@components/BetweenCurlyBraces/BetweenCurlyBraces.svelte';

  import { TECHNOLOGIES } from '../const/technologies.const.ts';
</script>

<article
  class="flex flex-col items-start justify-start gap-8 lg:gap-16 col-span-12 lg:col-start-8 lg:col-span-5"
>
  <BetweenCurlyBraces
    parentPropertyName=""
    css="lg:pl-0 text-xl lg:text-2xl font-extralight"
    curlyCss="text-2xl"
  >
    Flip is a <span class="text-green-main">complex social platform</span> developed at a great
    scale with service-oriented architecture. We’ve created a
    <span class="text-careers-purple">code ecosystem</span>
    with over <span class="text-careers-orange">130 auto-scalable microservices</span> behind the API
    gateway integrated with a unified logging layer and common monitoring.
  </BetweenCurlyBraces>

  <div class="flex flex-col gap-8 w-full">
    {#each TECHNOLOGIES as { name, technologies }}
      <div class="w-full flex flex-col font-source-pro uppercase text-2xl gap-4">
        <div class="py-4 border-b border-b-neutral-600 w-full text-white font-normal">
          <span>{name}</span>
        </div>
        <ul class="grid grid-cols-1 lg:grid-cols-2 w-full">
          {#each technologies as technology}
            <li class="w-full flex items-center text-base font-extralight">
              <div class="text-careers-basic-secondary min-w-[32px]">{technology.index}.</div>
              <span class="text-white">{technology.label}</span>
            </li>
          {/each}
        </ul>
      </div>
    {/each}
  </div>
</article>
