type Technology = {
  index: number;
  label: string;
};

export const BACKEND_TECHNOLOGIES: Technology[] = [
  { index: 1, label: 'NodeJS' },
  { index: 2, label: 'Nest.js' },
  { index: 3, label: 'TypeScript' },
  { index: 4, label: 'ElasticSearch' },
  { index: 5, label: 'Kafka' },
  { index: 6, label: 'RabbitMQ' },
  { index: 7, label: 'MongoDB' },
  { index: 8, label: 'PostgreSQL' },
  { index: 9, label: 'ScyllaDB' },
  { index: 10, label: 'TimescaleDB' },
  { index: 11, label: 'Redis' },
  { index: 12, label: 'Kubernetes' }
];
export const FRONTEND_TECHNOLOGIES: Technology[] = [
  { index: 13, label: 'Astro' },
  { index: 14, label: 'Svelte' },
  { index: 15, label: 'Next.js' },
  { index: 16, label: 'Angular' },
  { index: 17, label: 'TailwindCSS' },
  { index: 18, label: 'Styled Components' }
];
export const MOBILE_TECHNOLOGIES: Technology[] = [
  { index: 19, label: 'React Native' },
  { index: 20, label: 'Swift / ObjectiveC' },
  { index: 21, label: 'Kotlin / Java' }
];

export type TechnologyCategory = {
  name: string;
  technologies: Technology[];
};

export const TECHNOLOGIES: TechnologyCategory[] = [
  {
    name: 'Backend',
    technologies: BACKEND_TECHNOLOGIES
  },
  {
    name: 'Frontend',
    technologies: FRONTEND_TECHNOLOGIES
  },
  {
    name: 'Mobile',
    technologies: MOBILE_TECHNOLOGIES
  }
];
