<script lang="ts">
  import GreenCircle from '@components/SVGs/GreenCircle.svelte';
  import OrangeCircle from '@components/SVGs/OrangeCircle.svelte';
  import PurpleCircle from '@components/SVGs/PurpleCircle.svelte';
</script>

<div class="hidden lg:flex col-span-6">
  <figure class="w-full relative z-0">
    <figure class="circle-figure">
      <OrangeCircle />
    </figure>
    <figure class="circle-figure lg:-translate-x-[140px] xl:-translate-x-[160px]">
      <PurpleCircle />
    </figure>

    <figure class="circle-figure lg:-translate-x-[280px] xl:-translate-x-[320px]">
      <GreenCircle />
    </figure>
  </figure>
</div>

<style lang="postcss">
  .circle-figure {
    @apply absolute inset-y-0 right-0 z-10 flex w-auto items-center md:scale-[0.8] lg:scale-100;
  }
</style>
